.mitel-h1 {
  font-size: 20px;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.mitel-table-header {
  height: 39px;

  .mitel-table-header__individual {
    font-size: 14px;
    font-weight: 700 !important;
    text-transform: uppercase;
  }
}

.mitel-table-body {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.mitel-table th {
  border-bottom-width: 3px;
}

.mitel-table-body__hour {
  font-weight: 500 !important;
}

.mitel-table-body__nums {
  @extend .mitel-table-body;
}

.mitel-table-body__status {
  @extend .mitel-table-body;

  text-transform: uppercase;
}

.mitel-summary {
  font-size: 20px;
  font-weight: 700 !important;
}
