.vehicle-adjustments {
  .open-damage {
    height: 38px;
    // background: #FCFF5C;
    // border: 2px solid #ffffff;
    border-radius: 4px;
    text-align: center;
    padding: 0 2rem;
  }

  label,
  span {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    text-transform: uppercase;
  }
  &__general {
    > div {
      button {
        background: #e0e0e0;
        border: none !important;
        color: rgba(0, 0, 0, 0.87);

        &:hover {
          background: lighten(#e0e0e0, 0.1);
        }

        &[data-confrimed='false'][data-selected='true'] {
          background: #78ceff;
        }

        &[data-confrimed='true'][data-selected='true'] {
          background: #fcff5c;
        }
      }

      input[type='checkbox'] {
        &:checked {
          &[data-confirmed='true'] {
            + svg {
              color: #fcff5c !important;
              fill: #fcff5c !important;
              background: rgba(0, 0, 0, 0.7);
              border-radius: 3px;
              background: 'black';
            }
          }

          &[data-confirmed='false'] {
            + svg {
              color: #78ceff !important;
              fill: #78ceff !important;
            }
          }
        }
      }
    }
  }

  &__damage {
    max-height: 16px;
    position: relative;
    visibility: hidden;

    &.open {
      max-height: unset;
      padding: 30px 0;
      visibility: visible;
    }

    .close-damage {
      cursor: pointer;
      //   font-size: 2rem;
      //   position: absolute;
      //   top: 20px;
      //   right: 30px;
    }
  }
  &__inner {
    margin-top: 10px;
  }

  // &__type {
  //     padding: 20px 10px;
  //     &-list {
  //         align-items: start;
  //         display: grid;
  //         grid-template-columns: repeat(3, 1fr);
  //         gap: 10px;
  //     }
  // }
}
// .vehicle-adjustment {
//     box-shadow: 2px 2px 2px rgba(#000, 0.2);
//     &.MuiExpansionPanel-root.Mui-expanded {
//         margin: 0;
//     }
//     &__details {
//         border-top: 1px solid rgba(#000, 0.1);
//         padding-top: 20px;
//         width: 100%;
//     }
// }

.scratches-popup {
  .places {
    display: flex;
    justify-content: center;
  }

  .selector-container {
    .section {
      .MuiCardContent-root {
        > div {
          column-count: 2;
        }
      }

      .MuiCardHeader-root {
        padding-bottom: 0;
      }

      .item {
        align-items: center;
        // color: #fff;
        display: flex;
        font-weight: bold;
        margin-bottom: 20px;

        svg {
          background: #fff;
          border-radius: 100%;
          fill: #008625;
          margin-right: 7px;
          pointer-events: none;
        }
      }
    }
  }

  .selected-container {
    .selected {
      border-top: 0;
      border-radius: 0;
      margin-top: 0;

      .MuiCardHeader-root {
        padding-bottom: 0;
      }

      .MuiCardContent-root {
        column-count: 2;

        .item {
          align-items: center;
          display: flex;
          font-weight: bold;
          margin-bottom: 20px;

          svg {
            margin-right: 7px;
          }
        }
      }
    }
  }
}
