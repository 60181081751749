.vehicle-notes__label {
  font-size: 17px !important;
  color: #ffffff !important;
  width: 150px;
  text-align: center;
}

ul.vehicle-notes__list {
  border: 1.5px solid rgba(0, 0, 0, 0.23);
}

.vehicle-notes__list {
  padding: 10px 0px 20px 10px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
}

.vehicle-notes__paper {
  border: 1px solid rgba(255, 255, 255, 0.32) !important;
  max-height: 500px;
  overflow: auto;
  margin-bottom: 15px;
}

.vehicle-notes__paper:hover {
  border: 1px solid #000 !important;
}

.vehicle-notes__new-note {
  font-size: 16px !important;
  font-weight: 700 !important;
  margin: 0 !important;

  fieldset {
    top: 0 !important;
  }

}
