html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.MuiScopedCssBaseline-root {
  background: none
}