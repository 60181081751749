.imessage {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem;

  p {
    border-radius: 1.15rem;
    font-size: 0.8rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
  }

  p::before,
  p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }

  p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }

  p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }

  p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  p.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }
}