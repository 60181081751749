.disable-panel {
  position: relative;
  opacity: 0.2;

  &:before {
    background: rgba(0, 0, 0, 0.4);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
}

.cancel-booking__modal {
  .MuiDialog-container {
    .MuiPaper-root {
      width: 600px;
      background-color: #0739a0;
      border: 3px solid #ffffff;
    }

    .MuiTypography-root {
      font-size: 20px;
    }

    .MuiDialogContent-root {
      .MuiTypography-root {
        color: #ffffff;
        font-size: 16px;
      }
    }
  }

  .MuiDialogActions-root {
    .MuiButtonBase-root {
      color: #90caf9;
      font-size: 14px;
    }
  }
}
