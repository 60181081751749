.pricing-notes {
  //background: #880000 !important;
}

.pricing-notes__label {
  font-size: 17px !important;
  text-align: center;
  width: 150px;
}

.pricing-notes__list {
  border-color: white !important;
  font-family: Segoe UI, -apple-system, BlinkMacSystemFont, Roboto,
  Helvetica Neue, sans-serif;
  line-height: 20px !important;
  padding: 20px 10px !important;
}

.pricing-notes__paper {
  //border: 1px solid #a6534b !important;
  max-height: 200px;
  overflow: auto;
}
