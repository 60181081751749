@keyframes alert {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70% {
        transform: translateX(-5px);
    }

    20%,
    40%,
    60% {
        transform: translateX(5px);
    }

    80% {
        transform: translateX(3px);
    }

    90% {
        transform: translateX(-3px);
    }
}

body {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.01rem;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
}

.css-1hqe9yl-MuiSvgIcon-root, .css-9o2rks-MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
}

.highlight-green, .highlight-red {
    position: relative;
}

.highlight-green input {
    color: #04ff04;
}

.highlight-red input {
    color: red;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #A3A3A3;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}