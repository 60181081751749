.disabled {
  cursor: inherit !important;
  opacity: 0.5 !important;
}

.view-agents__banner {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  text-transform: uppercase;
}

.view-agents__banner-title {
  margin-right: 20px;
}

.view-agents__list-info {
  padding: 15px;
}

.view-agents__list-title {
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 15px 15px 0px 0px !important;
  text-transform: uppercase;
}
