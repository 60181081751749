.edit-email {
  & > div > .MuiPaper-root {
    align-self: flex-start;
  }

  &__subject {
    background: white;
    color: rgb(50, 49, 48);
    font-family: Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    font-weight: 600;
    padding: 1rem 0 0 1rem;
  }

  &__container, &__to, &__from {
    width: 100%;
  }

  &__from {
    font-size: 0.95rem;
    text-transform: capitalize;
  }

  &__to {
    font-size: 0.8rem;
    text-transform: capitalize;
  }

  &__header {
    align-items: center;
    background: white;
    color: rgb(50, 49, 48);
    display: flex;
    font-family: Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    height: 70px;
  }

  &__icon {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 70px;

    span {
      align-items: center;
      background: rgb(0, 120, 212);
      border-radius: 50%;
      color: white;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      height: 32px;
      justify-content: center;
      width: 32px;
    }
  }

  &__preview {
    border: 0;
    border-radius: 0 0 4px 4px;
    width: 100%;
  }
}