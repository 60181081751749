.mileage-confirm {
  position: relative;
}

.estimated-mileage {
  position: relative;

  label {
    color: red !important;
  }

  input {
    -webkit-text-fill-color: red !important;
  }

  fieldset {
    border-color: red !important;
  }
}