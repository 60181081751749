
.collection-panel__title {
  font-size: 14px;
  font-weight: 700 !important;
  margin-bottom: 5px;
  text-transform: uppercase;
}

legend {
  display: none;
}

fieldset {
  margin-top: 0 !important;
}
