.car-image {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 !important;
  padding: 0;
  width: 100%;
}

div.section {
  display: none;
}